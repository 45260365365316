@use '@angular/material' as mat;
@use 'theme' as *;
@import "./fonts.scss";

// Enable eprel theme for all components
@include mat.all-component-themes($eprel-theme);
@include mat.typography-hierarchy($eprel-typography);

html,
body {
    height: 100%;
    margin: 0;
}

/**
 * Generic reusable classes
 */
// Element that act as links, such as <a>
.link {
    color: $eprel-blue;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

// Class applied to the inserted <a> tag of routerLinkWrap directive 
.router-link-wrapper {
    margin: 0px !important;
    text-decoration: none !important;
    color: black
}

// Page titles
.page-title {
    display: flex;
    align-items: center;

    mat-icon {
        margin-right: 6px;
    }
}

// Descriptions
.description {
    font-size: 16px;
    letter-spacing: normal;
    margin: 14px 0px 24px 0px;
}

/**
 * Angular component style overrides
 */
// Button
.mdc-button {
    height: 48px !important;
    border-radius: 0px !important;

    &.small-button {
        height: 38px !important;
    }

    &.cancel {
        color: rgb(177, 177, 177) !important;
    }
}

// Table
.mat-mdc-table {
    border-top: 2px solid rgb(177, 177, 177, 0.3) !important;
    border-bottom: 1px solid rgb(177, 177, 177, 0.3) !important;

    .mat-mdc-header-row {
        height: 44px !important;
        background-color: rgb(244, 244, 244) !important;
        border-top: 2px rgb(177, 177, 177, 0.3) !important;

        th {
            font-style: normal !important;
            font-weight: 400 !important;
            font-size: 13px !important;
            letter-spacing: normal;
        }
    }

    .mat-mdc-row {
        height: 56px !important;
        transition: 0.1s ease;


        .mat-mdc-cell {
            letter-spacing: normal;
            padding: 4px 16px;
        }

        &.highlight-row {
            cursor: pointer;
        }

        &.selected.highlight-row {
            background-color: rgba(map-get($eprel-primary, 500), 0.05) !important;
        }

        &.selected.highlight-row:hover {
            background-color: rgba(map-get($eprel-primary, 500), 0.1) !important;
        }

        &:hover.highlight-row {
            background-color: rgba(0, 0, 0, 0.02) !important;

            & .rounded {
                background-color: rgb(230, 230, 230) !important;
            }
        }
    }
}

// Chip
.mat-mdc-chip {
    height: 26px !important;
    color: rgb(154, 154, 154);
    font-weight: 500;
    background-color: rgb(250, 250, 250) !important;
    font-size: 14px;
    transition: 0.1s ease;
    padding: 0px 8px 0px 4px;
    letter-spacing: normal;
    border: 1px solid rgb(231, 231, 231);

    .mdc-evolution-chip__action .mdc-evolution-chip__text-label {
        color: rgb(53, 53, 53) !important;

        mat-icon {
            transform: scale(0.85) translateY(-4px);
            margin-bottom: -10px;
            margin-left: -8px;
            color: rgb(53, 53, 53) !important;
            transition: 0.1s ease;
        }
    }




    // Visually indicate that chip can be used to route if routerLink is active on chip and user hovers over chip
    &[ng-reflect-router-link] {
        .mat-mdc-chip-action {
            cursor: pointer !important;
        }

        &:hover {
            border: 1px solid $eprel-blue;

            .mdc-evolution-chip__action .mdc-evolution-chip__text-label {
                color: $eprel-blue !important;
                transition: 0.1s ease;

                mat-icon {
                    color: $eprel-blue !important;
                    transition: 0.1s ease;
                }

            }
        }
    }

}


h1 {
    font-family: 'Work Sans' !important;
    font-size: 22px !important;
    font-weight: 600 !important;
    margin: 0px 0px 12px !important;
    color: rgb(53, 53, 53);
}

h3 {
    font-family: 'Work Sans' !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    margin: 0px !important;
    color: rgb(53, 53, 53);
}


p {
    font-family: 'Work Sans', 'Sans Serif' !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: rgb(95, 95, 95) !important;
    margin-bottom: 0px;
}

mat-chip {
    height: 22px !important;
    color: rgb(43, 43, 43);
}

.pointer {
    cursor: pointer;
}

// Dialogs
.mat-mdc-dialog-surface {
    border-radius: 0px !important;
}

// Selection overlays
.mat-mdc-select-panel {
    padding: 0px !important;
    border-radius: 0px !important;
}

.clear-form-field {
    border-left: 1px solid lightgray;
    margin-left: 10px;
    padding: 0px 4px !important;
    height: 56px !important;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.54);
    padding-top: 16px !important;
    margin-bottom: -16px;
}

.select-panel-unset-max-height {
    max-height: unset !important;
}

// Form fields
.mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
        border-radius: 0px;

        .mat-mdc-form-field-flex {
            margin-top: -4px;
        }
    }

    // Hide line ripple when not touched and valid
    .mdc-line-ripple::before {
        opacity: 0;
        transition: 0.3s ease;
    }

    &.ng-touched.ng-invalid {
        .mdc-line-ripple::before {
            opacity: 1;
        }
    }
}


.hidden {
    display: none !important;
}

.no-form-field-wrapper {
    .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }
}

.cdk-virtual-scroll-content-wrapper {
    max-width: 100% !important;
}

.mat-mdc-tab-body-content {
    overflow: hidden !important;
}