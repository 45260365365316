@use '@angular/material' as mat;

// Eprel angular material style overwrites
$eprel-blue-palette: (
    50 : #e8f0fc,
    100 : #c5daf7,
    200 : #9fc2f2,
    300 : #79a9ed,
    400 : #5c96e9,
    500 : #3f84e5,
    600 : #397ce2,
    700 : #3171de,
    800 : #2967da,
    900 : #1b54d3,
    A100 : #ffffff,
    A200 : #d4e0ff,
    A400 : #a1bcff,
    A700 : #88a9ff,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$eprel-red-palette: (
    50 : #fce5e5,
    100 : #f7bdbf,
    200 : #f29194,
    300 : #ed6569,
    400 : #e94449,
    500 : #e52329,
    600 : #e21f24,
    700 : #de1a1f,
    800 : #da1519,
    900 : #d30c0f,
    A100 : #fffefe,
    A200 : #ffcbcc,
    A400 : #ff9899,
    A700 : #ff7f80,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Primary, accent and warn used by angular
$eprel-primary: mat.define-palette($eprel-blue-palette, 500);
$eprel-accent: mat.define-palette(mat.$red-palette, A200, A100, A400);
$eprel-warn: mat.define-palette($eprel-red-palette);

// Custom color defintions
$eprel-blue: map-get($eprel-blue-palette, 500);
$eprel-green: #14b33c;

// Define eprel typography and theme
$eprel-typography: mat.define-typography-config($font-family: (
        'Work Sans', sans-serif),
    $headline-5: mat.define-typography-level($font-size: 22px, $font-weight: 600, $letter-spacing: normal),
        $button: mat.define-typography-level($font-size: 14px, $font-weight: 500, $letter-spacing: normal)
        );
    $eprel-theme: mat.define-light-theme((color: (primary: $eprel-primary, accent: $eprel-accent, warn: $eprel-warn, ), typography: $eprel-typography ));