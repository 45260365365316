@font-face {
    font-family: 'Work Sans';
    src: url('/assets/fonts/WorkSans/WorkSans-Light.ttf');
    font-weight: 300;
}

@font-face {
    font-family: 'Work Sans';
    src: url('/assets/fonts/WorkSans/WorkSans-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'Work Sans';
    src: url('/assets/fonts/WorkSans/WorkSans-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'Work Sans';
    src: url('/assets/fonts/WorkSans/WorkSans-SemiBold.ttf');
    font-weight: 600;
}

@font-face {
    font-family: 'Work Sans';
    src: url('/assets/fonts/WorkSans/WorkSans-Bold.ttf');
    font-weight: 700;
}


@font-face {
    font-family: 'Work Sans';
    src: url('/assets/fonts/WorkSans/WorkSans-LightItalic.ttf');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Work Sans';
    src: url('/assets/fonts/WorkSans/WorkSans-Italic.ttf');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Work Sans';
    src: url('/assets/fonts/WorkSans/WorkSans-MediumItalic.ttf');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Work Sans';
    src: url('/assets/fonts/WorkSans/WorkSans-SemiBoldItalic.ttf');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Work Sans';
    src: url('/assets/fonts/WorkSans/WorkSans-BoldItalic.ttf');
    font-weight: 700;
    font-style: italic;
}